import { mutedAtom, volumeAtom } from '@/atoms'
import { whisper } from '@/utils'
import { useAtom } from 'jotai'
import { useCallback } from 'react'

const useVolume = () => {
  const [volume, setVolume] = useAtom(volumeAtom)
  const [muted, setMuted] = useAtom(mutedAtom)

  const handleSetVolume = useCallback(
    (value: number) => {
      const newVolume = Math.round(Math.min(1, Math.max(0, value)) * 100) / 100
      setMuted(newVolume === 0)
      setVolume(newVolume)
    },
    [setMuted, setVolume],
  )

  const handleSetMuted = useCallback(
    (value: boolean) => {
      setMuted(value)
      if (!value) {
        setVolume((old) => (old === 0 ? 0.5 : old))
      }
    },
    [setMuted, setVolume],
  )

  return {
    volume,
    muted,
    setVolume: handleSetVolume,
    setMuted: handleSetMuted,
  }
}

export default useVolume
