import { type VideoProps } from '@/components/video'
import { nanoid } from 'nanoid'
import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import useVolume from './useVolume'
import Hls, {
  HlsConfig,
  LoaderCallbacks,
  LoaderConfiguration,
  LoaderContext,
} from 'hls.js'
import { formatCDN, getHashOrigin } from '@/utils'
import { useCachedSwitches } from './useSwitches'
import { useInView } from 'react-intersection-observer'

export interface UseVideoControlsParams {
  preload?: 'auto' | 'metadata' | 'none'
  videoElement: HTMLVideoElement | null
  containerElement: HTMLDivElement | null
  outerProps?: Partial<VideoProps>
}

const PlayingEvent = new EventTarget()

export interface UseVideoControlsResult {
  className?: string
  fullscreen: boolean
  muted: boolean
  playing: boolean
  currentTime: number
  onProgressChange: (progress: number) => void
  duration: number
  onPlay: () => void
  onPause: () => void
  onFullscreen: () => void
  onExitFullscreen: () => void
  onMute: () => void
  onUnMute: () => void
  togglePlayPause: () => void
  onLevelChange: (v: number) => void
  levelList: { text: string; value: number }[]
  currentLevel: number
  progressRef: React.RefObject<HTMLInputElement>
  loading: boolean
  ended: boolean
  videoProps?: Partial<VideoProps>
  resPopOpenChange: (v: boolean) => void
  resPopOpen: boolean
  inViewRef: any
}

/**
 * Rewrite the load method to add a query to each URL
 * to bypass caching requests from other domain names
 * and avoid cross domain issues
 * */
class CustomLoader extends Hls.DefaultConfig.loader {
  constructor(config: HlsConfig) {
    super(config)
  }

  load(
    context: LoaderContext,
    config: LoaderConfiguration,
    callbacks: LoaderCallbacks<LoaderContext>,
  ) {
    const url = new URL(context.url)
    const dStr = getHashOrigin()
    url.searchParams.append('d', dStr)
    context.url = url.toString()
    super.load(context, config, callbacks)
  }
}

const hlsConfig = {
  loader: CustomLoader,
}

const useVideoControls = ({
  preload,
  videoElement,
  outerProps,
  containerElement,
}: UseVideoControlsParams): UseVideoControlsResult => {
  const [isPlaying, setPlaying] = useState(false)
  const [uniqueId] = useState(nanoid())
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [isFullscreen, setFullscreen] = useState(false)
  const [resPopOpen, setResPopOpen] = useState(false)
  const progressRef = useRef<HTMLInputElement>(null)
  const [ended, setEnded] = useState(true)
  const [currentLevel, setLevel] = useState(-1)
  const [hasShown, setHasShown] = useState(false)
  const [hasAttach, setHasAttach] = useState(false)
  const [levelList, setLevelList] = useState<{ text: string; value: number }[]>(
    [],
  )
  const [loading, setLoading] = useState(
    !videoElement?.readyState && (preload === 'auto' || preload === 'metadata'),
  )
  const hlsRef = useRef<Hls>(new Hls(hlsConfig))
  const { muted, setMuted } = useVolume()
  const { data: switches, isValidating: switchesLoading } = useCachedSwitches()
  const useCDNVB4 = !!switches?.cdnvb4

  const handleTimeUpdate = useCallback(() => {
    if (!videoElement) {
      return
    }
    const { currentTime, duration } = videoElement
    const progress = duration > 0 ? (currentTime / duration) * 100 : 0
    if (progressRef.current) {
      progressRef.current.value = String(progress)
    }

    setCurrentTime(currentTime)
    setDuration(duration)
  }, [videoElement])

  const handlePlay = useCallback(
    (e: any) => {
      setPlaying(true)
      setEnded(false)
      const playingEvent = new CustomEvent('VideoPlaying', { detail: uniqueId })
      PlayingEvent.dispatchEvent(playingEvent)
      outerProps?.onPlay?.(e)
    },
    [uniqueId, outerProps],
  )

  const handlePause = useCallback(
    (e: any) => {
      setPlaying(false)
      outerProps?.onPause?.(e)
    },
    [outerProps],
  )

  const handleLoaded = useCallback(() => {
    setLoading(false)
    if (isPlaying) {
      videoElement?.play()?.catch(() => {})
    }
  }, [isPlaying, videoElement])

  const handleLoadedMetadata: VideoProps['onLoadedMetadata'] = useCallback(
    (e: any) => {
      setLoading(false)
      if (!videoElement) {
        return
      }
      setDuration(videoElement.duration)

      if (isPlaying) {
        videoElement?.play()?.catch(() => {})
      }
      outerProps?.onLoadedMetadata?.(e)
    },
    [videoElement, isPlaying, outerProps],
  )

  const refreshFullscreen = useCallback(() => {
    if (!!document.fullscreenElement) {
      videoElement?.play().catch(() => {})
    }
    setFullscreen(!!document.fullscreenElement)
    // (videoElement as any)?.webkitDisplayingFullscreen,
  }, [videoElement])

  const handleEnded = useCallback(
    (e: any) => {
      setEnded(true)
      setPlaying(false)
      outerProps?.onEnded?.(e)
    },
    [outerProps],
  )

  const parsedSrc = useMemo(() => {
    if (useCDNVB4) {
      return formatCDN(outerProps?.src)
    }
    return outerProps?.src
  }, [outerProps?.src, useCDNVB4])

  const { ref: inViewRef } = useInView({
    threshold: 0.75,
    onChange(inView, entry) {
      if (inView) {
        setHasShown(true)
      }
    },
  })

  useEffect(() => {
    if (!hlsRef.current.levels.length && hasShown && hasAttach) {
      hlsRef.current.loadSource(parsedSrc || '')
    }
  }, [hasShown, hasAttach, parsedSrc])

  useEffect(() => {
    if (videoElement) {
      if (/\.mp4/.test(parsedSrc || '')) {
        videoElement.src = parsedSrc || ''
      } else if (/\.m3u8/.test(parsedSrc || '') && Hls.isSupported()) {
        const hls = hlsRef.current
        hls.config.maxMaxBufferLength = 10
        hls.attachMedia(videoElement)
        hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
            const levels = data.levels
              .map((item, value) => {
                const url = item.url[0] || ''
                let level = 360
                if (/media\-hd/.test(url)) {
                  level = 720
                } else if (/media\-fhd/.test(url)) {
                  level = 1080
                } else if (/media\-sd/.test(url)) {
                  level = 360
                }
                return { text: level + 'p', level, value }
              })
              .sort((a, b) => b.level - a.level)
            levels.push({ text: 'Auto', level: -1, value: -1 })
            setLevelList(levels)
          })
          setHasAttach(true)
        })
      }
    }
  }, [videoElement, parsedSrc])

  const onLevelChange = (value: number) => {
    if (value === currentLevel) return
    hlsRef.current.currentLevel = value
    setLevel(value)
    setResPopOpen(false)
  }

  const resPopOpenChange = (v: boolean) => {
    setResPopOpen(v)
  }

  const videoProps: Partial<VideoProps> = useMemo(() => {
    return {
      muted,
      onPlay: handlePlay,
      onPause: handlePause,
      onTimeUpdate: handleTimeUpdate,
      onLoadedMetadata: handleLoadedMetadata,
      onEnded: handleEnded,
      onLoadedData: handleLoaded,
    }
  }, [
    muted,
    handlePlay,
    handlePause,
    handleTimeUpdate,
    handleLoadedMetadata,
    handleEnded,
    handleLoaded,
  ])

  useEffect(() => {
    document.addEventListener('fullscreenchange', refreshFullscreen)

    if (!videoElement) {
      return
    }
    setDuration(videoElement?.duration)

    return () => {
      document.removeEventListener('fullscreenchange', refreshFullscreen)
    }
  }, [
    videoElement,
    handlePlay,
    handlePause,
    handleTimeUpdate,
    handleLoadedMetadata,
    refreshFullscreen,
    handleEnded,
    handleLoaded,
  ])

  const handlePlayPause = useCallback(() => {
    if (!videoElement) {
      return
    }
    setPlaying((playing) => {
      if (playing) {
        videoElement.pause()
      } else {
        videoElement.play()?.catch(() => {})
      }
      return !playing
    })
  }, [videoElement])

  const handleProgressChange = useCallback(
    (newProgress: number) => {
      if (!videoElement) {
        return
      }
      const newTime = (newProgress / 100) * videoElement.duration
      if (isFinite(newTime) && !isNaN(newTime)) {
        setCurrentTime(newTime)
        videoElement.currentTime = newTime
      }
    },
    [videoElement],
  )

  const enterFullscreen = useCallback(async () => {
    if (containerElement?.requestFullscreen) {
      await containerElement?.requestFullscreen()
      setFullscreen(true)
    } else if ((videoElement as any)?.webkitEnterFullscreen) {
      await (videoElement as any)?.webkitEnterFullscreen()
      setFullscreen(true)
    }
  }, [containerElement, videoElement])

  const exitFullscreen = useCallback(() => {
    if (document.fullscreenElement) {
      document.exitFullscreen?.()
    } else if ((videoElement as any)?.webkitExitFullscreen) {
      ;(videoElement as any).webkitExitFullscreen()
    }
    setFullscreen(false)
  }, [videoElement])

  const handleToggleFullscreen = useCallback(() => {
    const isFullscreen =
      document.fullscreenElement === containerElement ||
      (videoElement as any).webkitDisplayingFullscreen
    if (isFullscreen) {
      exitFullscreen()
    } else {
      enterFullscreen()
    }
  }, [enterFullscreen, exitFullscreen, containerElement, videoElement])

  const handleToggleMuted = useCallback(
    (muted: boolean) => {
      setMuted(muted)
      if (videoElement) {
        videoElement.muted = muted
      }
    },
    [videoElement, setMuted],
  )

  const handleUnMute = useCallback(() => {
    handleToggleMuted(false)
  }, [handleToggleMuted])

  const handleMute = useCallback(() => {
    handleToggleMuted(true)
  }, [handleToggleMuted])

  useEffect(() => {
    const pauseVideo = (e: CustomEvent<string>) => {
      const playingId = e.detail
      if (playingId === uniqueId) return
      videoElement?.pause()
    }
    PlayingEvent.addEventListener('VideoPlaying', pauseVideo)
    return () => PlayingEvent.removeEventListener('VideoPlaying', pauseVideo)
  }, [uniqueId, videoElement])

  return {
    playing: isPlaying,
    fullscreen: isFullscreen,
    muted,
    currentTime,
    duration,
    ended,
    loading,
    onExitFullscreen: handleToggleFullscreen,
    onFullscreen: handleToggleFullscreen,
    onMute: handleMute,
    onUnMute: handleUnMute,
    onProgressChange: handleProgressChange,
    onPlay: handlePlayPause,
    togglePlayPause: handlePlayPause,
    progressRef,
    onPause: handlePlayPause,
    videoProps,
    onLevelChange,
    levelList,
    currentLevel,
    resPopOpen,
    resPopOpenChange,
    inViewRef,
  }
}

export default useVideoControls
