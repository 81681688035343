import {
  ComponentProps,
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { PoNVoid } from '@/types'
import VideoControls, { VideoControlsProps } from '../video-controls'
import useVideoControls from '@/hooks/useVideoControls'
import IconPlay from '@haiper/icons-svg/icons/solid/play.svg'
import Loading from '@/components/loading'
import Button from '../button'
import IconNSFW from '@haiper/icons-svg/icons/outline/error.svg'
import IconBlock from '@haiper/icons-svg/icons/outline/block.svg'
import { useAtom } from 'jotai'
import { allowedNSFWCreationIdsAtom } from '@/atoms'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import useVolume from '@/hooks/useVolume'
import { useCachedSwitches } from '@/hooks/useSwitches'
import { cls, formatCDN, isMobile, whisper } from '@/utils'

export interface VideoApi {
  play: () => void
  pause: () => void
  mute: () => void
  unmute: () => void
  togglePlayPause: () => void
}

export interface VideoProps extends ComponentProps<'video'> {
  onClick?: () => PoNVoid
  containerClassName?: string
  roundedClassName?: string
  maskClassName?: string
  playClassName?: string
  canPlay?: boolean
  playOnHover?: boolean
  nsfw?: boolean
  illegal?: boolean
  creationId?: string
  onHoverPlayStart?: () => PoNVoid
  onHoverPlayEnd?: () => PoNVoid
  controlsProps?: Partial<VideoControlsProps>
  blurBg?: boolean
  showPosterOnEnded?: boolean
  api?: RefObject<VideoApi>
}

const Video = forwardRef<HTMLVideoElement, VideoProps>(
  (
    {
      className,
      containerClassName,
      roundedClassName,
      maskClassName,
      playClassName,
      canPlay = true,
      nsfw,
      illegal, // FIXME:
      controls,
      onClick,
      creationId = '',
      playOnHover,
      blurBg,
      preload = 'metadata',
      onHoverPlayStart,
      onHoverPlayEnd,
      onTimeUpdate,
      showPosterOnEnded = true,
      controlsProps,
      api,
      ...props
    },
    ref,
  ) => {
    const { data: switches, isValidating: switchesLoading } =
      useCachedSwitches()
    const useCDNVB4 = !!switches?.cdnvb4

    const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(
      null,
    )
    const { isBelowMd } = useBreakpoint('md')
    const [containerElement, setContainerElement] =
      useState<HTMLDivElement | null>(null)
    const [allowedNSFWCreationIds, setAllowedNSFWCreationIds] = useAtom(
      allowedNSFWCreationIdsAtom,
    )

    const [videoControlsHovering, setVideoControlsHovering] = useState(false)

    const showNSFW = useMemo(() => {
      return !!nsfw && !allowedNSFWCreationIds.includes(creationId) && !illegal // don't show NSFW mask if illegal
    }, [allowedNSFWCreationIds, nsfw, illegal, creationId])

    const [hovering, setHovering] = useState(false)
    const autoPlay =
      (props.autoPlay || (playOnHover && hovering)) && canPlay && !showNSFW

    const videoControlsProps = useVideoControls({
      outerProps: props,
      videoElement,
      containerElement,
    })

    const {
      loading,
      playing,
      ended,
      videoProps,
      onMute,
      onUnMute,
      inViewRef,
      fullscreen,
    } = videoControlsProps
    const { volume } = useVolume()

    useImperativeHandle(api, () => {
      return {
        play: () => {
          if (videoElement) {
            videoElement.play().catch(() => {})
          }
        },
        pause: () => {
          if (videoElement) {
            videoElement.pause()
          }
        },
        mute: () => {
          if (videoElement) {
            videoElement.muted = true
            onMute?.()
          }
        },
        unmute: () => {
          if (videoElement) {
            videoElement.muted = false
            onUnMute?.()
          }
        },
        togglePlayPause: () => {
          if (videoElement) {
            if (videoElement.paused) {
              videoElement.play().catch(() => {})
            } else {
              videoElement.pause()
            }
          }
        },
      }
    })

    useEffect(() => {
      const lastVideoElement = videoElement
      return () => {
        // manually pause and clear video src to cancel pending requests
        if (lastVideoElement) {
          lastVideoElement.pause?.()
          lastVideoElement.src = ''
          lastVideoElement.poster = ''
          lastVideoElement.load?.()
        }
      }
    }, [videoElement])

    useEffect(() => {
      if (videoElement) {
        videoElement.volume = volume
      }
    }, [volume, videoElement])

    const handleTimeUpdate = useCallback(
      (e: any) => {
        onTimeUpdate?.(e)
        videoProps?.onTimeUpdate?.(e)
        if (hovering && e?.target?.currentTime < 0.001) {
          onHoverPlayEnd?.()
        }
      },
      [onHoverPlayEnd, hovering, onTimeUpdate, videoProps],
    )

    // mouse not moving for 3s
    const [mouseMoving, setMouseMoving] = useState(false)

    const movingTimeoutCallback = useCallback(() => {
      setMouseMoving(false)
    }, [])

    const movingTimeoutRef = useRef<NodeJS.Timeout | null>(null)

    // save setTimeout handler to ref
    const handleMouseMove = useCallback(() => {
      setMouseMoving(true)
      if (movingTimeoutRef.current) {
        clearTimeout(movingTimeoutRef.current)
      }
      movingTimeoutRef.current = setTimeout(movingTimeoutCallback, 3000)
    }, [movingTimeoutCallback])

    const showVideoControls =
      videoControlsHovering ||
      ((!ended || (hovering && mouseMoving)) &&
        controls &&
        (hovering || isBelowMd) &&
        (!playing || (hovering && mouseMoving)))

    const togglePlayPause = (e: any) => {
      e?.preventDefault()
      e?.stopPropagation()
      if (!canPlay) return
      videoControlsProps?.togglePlayPause()
    }

    const handleClick = useCallback(
      async (e: any) => {
        e?.preventDefault()
        e?.stopPropagation()
        if (illegal) {
          return
        }
        if (controls && !isMobile() && !fullscreen) {
          videoControlsProps.onFullscreen()
        } else if (!isMobile()) {
          await onClick?.()
        }
        if (!showNSFW && canPlay) {
          videoControlsProps?.togglePlayPause?.()
        }
        handleMouseMove()
      },
      [
        handleMouseMove,
        videoControlsProps,
        showNSFW,
        canPlay,
        illegal,
        onClick,
        controls,
        fullscreen,
      ],
    )

    const hideNSFWMask = useCallback(
      (e: any) => {
        e?.stopPropagation?.()
        e?.preventDefault?.()

        setAllowedNSFWCreationIds((old) => {
          if (old.includes(creationId)) {
            return old
          }
          return [...old, creationId]
        })
        videoElement?.play().catch(() => {})
      },
      [videoElement, setAllowedNSFWCreationIds, creationId],
    )

    useEffect(() => {
      return () => {
        setAllowedNSFWCreationIds((old) =>
          old.filter((id) => id !== creationId),
        )
      }
    }, [setAllowedNSFWCreationIds, creationId])

    const maskStyle = cls(
      'absolute inset-0 flex justify-center items-center z-30 bg-surface text-text backdrop-blur-[50px] rounded-[6px] pointer-events-none bg-transparent',
      roundedClassName,
      maskClassName,
    )
    const maskButtonStyle =
      'rounded-md border border-solid border-border pointer-events-auto text-body-md h-9 md:h-10'

    const handleEnded = useCallback(
      (e: any) => {
        const outerOnEnded = props.onEnded ?? videoProps?.onEnded
        outerOnEnded?.(e)
        if (showPosterOnEnded && !autoPlay) {
          videoElement?.load()
          videoElement?.pause()
        }
      },
      [
        showPosterOnEnded,
        videoElement,
        props.onEnded,
        videoProps?.onEnded,
        autoPlay,
      ],
    )

    const parsedSrc = useMemo(() => {
      if (useCDNVB4) {
        return formatCDN(props.src)
      }
      return props.src
    }, [props.src, useCDNVB4])

    const parsedPoster = useMemo(() => {
      if (useCDNVB4) {
        return formatCDN(props.poster)
      }
      return props.poster
    }, [props.poster, useCDNVB4])

    return (
      <div
        ref={(node) => setContainerElement(node)}
        className={cls(
          '@container size-full max-w-full max-h-full flex items-center rounded-[6px] overflow-hidden',
          roundedClassName,
          containerClassName,
        )}
      >
        <div
          ref={inViewRef}
          className={cls(
            'relative size-full max-w-full max-h-full flex items-center rounded-[6px]',
            roundedClassName,
          )}
        >
          <video
            ref={(node) => {
              setVideoElement(node)
              if (typeof ref === 'function') {
                ref(node)
              } else if (ref) {
                ref.current = node
              }
            }}
            playsInline
            {...props}
            {...videoProps}
            loop={playOnHover}
            autoPlay={autoPlay}
            className={cls(
              'w-full h-auto max-h-full max-w-full rounded-[6px] z-10',
              blurBg ? 'bg-transparent' : 'bg-surface-base',
              roundedClassName,
              className,
              showNSFW ? 'invisible' : '',
            )}
            src={parsedSrc}
            poster={parsedPoster}
            controlsList='nodownload noremoteplayback nocontextmenu noseeking noplaybackrate novolume noopenwith nopictureinpicture'
            preload={preload}
            onClick={handleClick}
            onTimeUpdate={handleTimeUpdate}
            onEnded={handleEnded}
          >
          </video>
          {blurBg && (
            <div
              className={cls(
                'absolute inset-0 bg-cover bg-center bg-no-repeat backdrop-blur-[50px] z-0 blur-md',
                showNSFW ? 'hidden' : '',
              )}
              style={{
                backgroundImage: `url(${parsedPoster})`,
              }}
            />
          )}
          <div
            className={cls(
              'absolute inset-0 pointer-events-none bg-transparent z-20',
              showNSFW ? 'hidden' : '',
            )}
          >
            <div className='size-full flex items-center justify-center'>
              <div
                className='hover:opacity-80 md:active:opacity-80 pointer-events-auto'
                onClick={togglePlayPause}
              >
                {(playing && props.src) || loading ? (
                  loading ? (
                    <Loading className='text-white size-8' />
                  ) : null
                ) : (
                  <IconPlay
                    width={32}
                    height={32}
                    className={cls(
                      'text-white size-8 cursor-pointer z-20',
                      playing && props.src ? 'hidden' : '',
                      playClassName,
                    )}
                  />
                )}
              </div>
            </div>
          </div>
          <div
            className={cls(maskStyle, showNSFW ? 'flex' : 'hidden')}
            aria-label='nsfw mask'
          >
            <div
              className='flex flex-col gap-2 md:gap-4 items-center justify-between'
              aria-label='nsfw-mask-inner'
            >
              <IconNSFW alt='eye-slash' className='size-12 text-icon' />
              <div className='text-body-md tracking-15'>
                This video may contain explicit content
              </div>
              <Button
                variant='transparent'
                className={maskButtonStyle}
                onClick={hideNSFWMask}
              >
                Watch Video
              </Button>
            </div>
          </div>
          <div
            className={cls(maskStyle, 'p-10', !!illegal ? 'flex' : 'hidden')}
            aria-label='illegal mask'
          >
            <div
              className='flex flex-col gap-4 @md:gap-6 items-center justify-between'
              aria-label='nsfw-mask-inner'
            >
              <IconBlock alt='eye-slash' className='size-12' />
              <div className='flex flex-col gap-1 items-center'>
                <div className='font-medium text-body-md'>
                  Haiper doesn't allow content that encourages harmful or
                  illegal activities
                </div>
              </div>
              <div className='h-0'></div>
            </div>
          </div>
        </div>
        <div
          className={cls('absolute inset-0 size-full z-20')}
          // update hover
          onMouseEnter={() => {
            setHovering(true)
            if (
              playOnHover &&
              videoElement?.paused &&
              !showNSFW &&
              !isBelowMd &&
              !videoControlsProps.fullscreen
            ) {
              try {
                void videoElement?.play().catch((err) => {
                  if (
                    err?.name === 'NotAllowedError' &&
                    videoElement.muted !== true
                  ) {
                    videoElement.muted = true
                    onMute?.()
                    void videoElement?.play().catch(() => {})
                  }
                })
                onHoverPlayStart?.()
              } catch (error) {
                // do nothing
                console.error('error', error)
              }
            }
          }}
          onMouseLeave={() => {
            setHovering(false)
            if (
              playOnHover &&
              !videoElement?.paused &&
              !videoControlsProps.fullscreen
            ) {
              try {
                void videoElement?.pause()
              } catch (error) {
                // do nothing
              }
            }
          }}
          onMouseMove={handleMouseMove}
          onClick={handleClick}
        >
          <div
            className={cls(
              'absolute bottom-0 inset-x-0 w-full text-icon-on-color',
              showVideoControls ? 'block' : 'hidden',
            )}
            aria-label='video controls container'
          >
            <VideoControls
              {...videoControlsProps}
              {...controlsProps}
              className={cls(
                roundedClassName,
                'w-full rounded-t-none',
                controlsProps?.className,
              )}
              onMouseEnter={() => {
                setVideoControlsHovering(true)
              }}
              onMouseLeave={() => {
                setVideoControlsHovering(false)
              }}
            />
          </div>
        </div>
      </div>
    )
  },
)

Video.displayName = 'Video'

export default Video
